import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { breakpoints, unitConverter as uc } from '../../../styles/base';
import PageWrapper from '../PageWrapper/PageWrapper';
import TextBlock from '../../molecules/TextBlock/TextBlock';
import sanityImage from '../../../utils/sanityImage';
import Image from '../../atoms/Image/Image';
import Section from '../../molecules/Section/Section';

const RadialIconSection = ({
  radialIconCenterLogo,
  radialIcons,
  sectionStyles,
  textBlock,
}) => {
  const textBlockCss = css`
    max-width: ${uc('680px')};
  `;

  const radialIconArraySectionCss = css`
    display: grid;
    grid-template-areas: '. . d . .' '. b d f .' 'a b i f h' 'a c i g h' '. c e g .' '. . e . .';
    max-width: ${uc('1120px')};
    min-height: ${uc('800px')};
    margin: 0 auto;
    padding-bottom: ${uc('112px')};

    @media (${breakpoints.tablet}) {
      grid-template-areas: 'a b c' 'd i e' 'f g h';
    }

    @media (${breakpoints.mobile}) {
      display: flex;
      min-height: 0;
      padding-bottom: 0;
      overflow-x: auto;
    }
  `;

  const radialIconArrayIconContainerCss = css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${uc('132px')};
    height: ${uc('132px')};
    opacity: 0;
    place-self: center;

    &.-activated {
      animation: radial-icon-in 0.5s forwards;
    }

    @keyframes radial-icon-in {
      0% {
        transform: translateX(20%);
        opacity: 0;
      }

      100% {
        transform: translateX(0);
        opacity: 1;
      }
    }

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      background: white;
      box-shadow: ${uc(
        '0 0 10px #eaeaea, 0 10px 85px #f7f7f7, 10px 10px 15px #f7f7f7, -10px 10px 15px #f7f7f7'
      )};
      transform: skew(0deg, -30deg);
    }

    &:first-of-type {
      grid-area: a;
      animation-delay: 0.3s;
    }

    &:nth-of-type(2) {
      grid-area: b;
      animation-delay: 0.35s;
    }

    &:nth-of-type(3) {
      grid-area: c;
      animation-delay: 0.35s;
    }

    &:nth-of-type(4) {
      grid-area: d;
      animation-delay: 0.4s;
    }

    &:nth-of-type(5) {
      grid-area: e;
      animation-delay: 0.4s;
    }

    &:nth-of-type(6) {
      grid-area: f;
      animation-delay: 0.45s;
    }

    &:nth-of-type(7) {
      grid-area: g;
      animation-delay: 0.45s;
    }

    &:nth-of-type(8) {
      grid-area: h;
      animation-delay: 0.5s;
    }

    &:last-of-type {
      grid-area: i;
      animation-delay: 0s;

      &::before {
        display: none;
      }

      img {
        width: auto;
      }

      @media (${breakpoints.mobile}) {
        display: none;
      }
    }

    @media (${breakpoints.tablet}) {
      width: ${uc('150px')};
      height: ${uc('150px')};
    }

    @media (${breakpoints.mobile}) {
      flex: 0 0 33%;
      height: auto;
      margin: ${uc('0 10px')};

      &::before {
        content: none;
      }
    }
  `;

  const radialIconImageCss = css`
    position: relative;
    width: ${uc('84px')};

    @media (${breakpoints.tablet}) {
      width: ${uc('125px')};
    }
  `;

  const renderRadialIcons = () => {
    if (!radialIcons) {
      return null;
    }

    // add center logo to radial icon array
    const completeSetOfIcons = radialIcons.concat([radialIconCenterLogo]);

    // render the component
    return completeSetOfIcons.map(radialIcon => (
      <div
        className="radial-icon-array__icons"
        css={radialIconArrayIconContainerCss}
        key={`image-component-${radialIcon._key}`}
      >
        {radialIcon.asset && (
          <Image
            css={radialIconImageCss}
            src={sanityImage(radialIcon)
              .auto('format')
              .width(140)
              .fit('max')
              .url()}
          />
        )}
      </div>
    ));
  };

  const [radialIconJsLoaded, setRadialIconJsLoaded] = useState(false);

  // use react hook to have JS init after dom renders so that classes exists
  useEffect(() => {
    if (!radialIconJsLoaded) {
      import('./assets/js/radial-icon-array')
        .then(radialIconJS => {
          radialIconJS.default.init();
        })
        .catch(error => console.error(error)); // eslint-disable-line no-console
      setRadialIconJsLoaded(true);
    }
  }, []);

  return (
    <Section sectionStyles={sectionStyles}>
      <PageWrapper>
        {textBlock && <TextBlock css={textBlockCss} {...textBlock} />}
        <div
          className="radial-icon-array__container"
          css={radialIconArraySectionCss}
        >
          {renderRadialIcons()}
        </div>
      </PageWrapper>
    </Section>
  );
};

RadialIconSection.propTypes = {
  radialIcons: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  radialIconCenterLogo: PropTypes.shape({}),
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  textBlock: PropTypes.shape({}),
};

RadialIconSection.defaultProps = {
  radialIcons: null,
  radialIconCenterLogo: null,
  sectionStyles: {},
  textBlock: null,
};

export default RadialIconSection;
